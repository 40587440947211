<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-lone-template -->
<template>
  <div id="chatpage" class="basic-vue-chat">
    <section class="window">
      <div class="back" @click="handleClose">
        <span class="material-icons">arrow_back_ios</span>
      </div>
      <header class="window__header__container" :class="{ blurred: needConfirmation|noTokens|showModal|showInputEmail}">
        <div class="icon-container">
          <nuxt-link :to="`/girls/${modelId}`">
            <img :src="modelIcon" alt="avatar">
            <div v-if="isOnline" class="status-circle" />
          </nuxt-link>
        </div>
        <slot name="header">
          {{ title }}
        </slot>
        <nuxt-link to="/chats" class="mail_icon">
          <CommentMultiple class="icon" fill-color="white" title="All Chats" :size="40" />
          <span v-if="unread" class="alert alert-msg">{{ unread }}</span>
        </nuxt-link>
      </header>

      <section
        id="window__messages__container"
        class="window__messages__container"
        :class="{ blurred: needConfirmation|noTokens|showModal|showInputEmail}"
      >
        <slot>
          <messages-list
            :feed="msglist"
            :author-id="authorId"
            class="messages-list"
          />
        </slot>

        <Loader v-if="isLoading" class="icon-loading" />
      </section>
      <WelcomeChat v-if="sayHi" :model-name="title" :class="{ blurred: needConfirmation|noTokens|showModal|showInputEmail}" @sendHi="sendHi" />
      <WhatsAppFlow v-if="needConfirmation" @close="closePhoneVerify" />
      <TokensTopUp v-if="noTokens" @close="handleCloseTopUp" />
      <ContentModalEmailVerify v-if="showModal" :email="profileData.email" @close-modal="handleCloseModalEmail" @click-modal="showModalEmail = false" />
      <ModalInputEmailVerify v-if="showInputEmail" :model-name="title" @close-modal="handleCloseModalEmail" @click-modal="handleEmailInput" />
      <div class="window__input__container" :class="{ blurred: needConfirmation|noTokens|showModal|showInputEmail}">
        <slot name="input-container">
          <input-container
            ref="inputText"
            :title="$t('buttons.send')"
            @input="onInputMsg"
            @newOwnMessage="onNewOwnMessage"
          />
        </slot>
      </div>

      <!-- <div class="more"><span class="material-icons">more_horiz</span></div> -->
    </section>
    <Modal v-if="btnClicked" class="camclick" @close="closeModal">
      <template>
        <div class="modal-wrapper-img">
          <Ok :size="96" fill-color="#57CE70" />
        </div>
        <div class="info" v-html="modalInfo" />
        <Button
          :title="$t('like.close')"
          @click.native="closeModal"
        />
      </template>
    </Modal>
    <!-- <div v-html="getHtml(pixel)" v-if="pixel" style="display: none"></div>        -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Ok from 'vue-material-design-icons/CheckCircleOutline.vue';
import CommentMultiple from 'vue-material-design-icons/EmailOutline.vue';
import Loader from '../Loader/Loader';
import MessagesList from './messages/MessagesList.vue';
import InputContainer from './input/InputContainer.vue';
import TokensTopUp from '@/components/TokensTopUp/TokensTopUp';
import WhatsAppFlow from '@/components/WhatsAppFlow/WhatsAppFlow';
import WelcomeChat from '@/components/WelcomeChat/WelcomeChat.vue';
import ContentModalEmailVerify from '@/components/ContentModalEmailVerify/ContentModalEmailVerify';
import ModalInputEmailVerify from '@/components/ModalInputEmailVerify/ModalInputEmailVerify';
import { Namespaces } from '@/utils/constants/store.js';

const {
  // mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);

export default {
  name: 'BasicVueChat',
  components: {
    MessagesList,
    InputContainer,
    WhatsAppFlow,
    TokensTopUp,
    WelcomeChat,
    Loader,
    ContentModalEmailVerify,
    CommentMultiple,
    ModalInputEmailVerify,
    Ok
  },
  props: {
    title: {
      type: String,
      default: 'Chat',
      required: false
    },
    initialAuthorId: {
      type: Number,
      default: 0,
      required: false
    },
    modelIcon: {
      type: String,
      default: '',
      required: false
    },
    modelId: {
      type: Number,
      default: 0
    },
    initialFeed: {
      type: Array,
      default: function () {
        return [];
      },
      required: false
    },
    newMessage: {
      type: Object,
      default: function () {
        return {};
      },
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isOnline: {
      type: Number,
      default: 0
    },
    wa: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      feed: [],
      needConfirmation: false,
      showModal: false,
      noTokens: false,
      sayHi: false,
      authorId: 0,
      showedModalEmail: false,
      showInputEmail: false,
      modalInfo: this.$t('chat.camclick'),
      btnClicked: 0
    };
  },
  computed: {
    ...profileGetters(['profileData']),
    unread () {
      return this.$store.state.messages.unread.length;
    },
    msglist () {
      const sortBy = (function () {
        const toString = Object.prototype.toString;
        // default parser function
        const parse = function (x) { return x; };
        // gets the item to be sorted
        const getItem = function (x) {
          const isObject = x != null && typeof x === 'object';
          const isProp = isObject && this.prop in x;
          return this.parser(isProp ? x[this.prop] : x);
        };
        /**
         * Sorts an array of elements.
         *
         * @param {Array} array: the collection to sort
         * @param {Object} cfg: the configuration options
         * @property {String}   cfg.prop: property name (if it is an Array of objects)
         * @property {Boolean}  cfg.desc: determines whether the sort is descending
         * @property {Function} cfg.parser: function to parse the items to expected type
         * @return {Array}
         */
        return function sortby (array, cfg) {
          if (!(array instanceof Array && array.length)) { return []; }
          if (toString.call(cfg) !== '[object Object]') { cfg = {}; }
          if (typeof cfg.parser !== 'function') { cfg.parser = parse; }
          cfg.desc = cfg.desc ? -1 : 1;
          return array.sort(function (a, b) {
            a = getItem.call(cfg, a);
            b = getItem.call(cfg, b);
            return cfg.desc * (a < b ? -1 : +(a > b));
          });
        };
      }());

      const ids = new Set(this.initialFeed.map(d => d.id));
      // const list = [...this.initialFeed, ...msg.list.filter(msg => +msg.model_id === +this.modelId && !ids.has(msg.id))];
      const list = [...this.$store.state.chat.messages, ...this.$store.state.messages.list.filter(msg => +msg.model_id === +this.modelId && !ids.has(msg.id))];
      // this.$store.commit('messages/setSeenState', this.$store.state.messages.list.filter(msg => msg.model_id == this.modelId && msg.bot == 1))
      // this.$store.state.messages.list.forEach(item => {
      //   this.$store.state.messages.list.map(x => (x.msg_id === item.msg_id && x.model_id == item.model_id ? { ...x, seen: true } : x))
      // })
      // this.$store.state.messages.list = this.$store.state.messages.list.map(x => (x.msg_id === msg.msg_id && x.model_id == msg.model_id ? { ...x, seen: true } : x))
      // console.log("MSGs", list)
      return sortBy(list, { prop: 'created_at' });
    },
    modelOnline () {
      return this.isOnline && !this.needConfirmation;
    },
    tokensLeft () {
      return this.profileData.count_tokens;
    }
  },
  watch: {
    newMessage: function (newValue, oldValue) {
      this.pushToFeed(newValue);
      // console.log("MSG", newValue)
    },
    wa: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.needConfirmation = newValue;
      }
      // console.log("wa", newValue)
    },
    initialFeed () {
      // console.log('feed',this.initialFeed)
      this.sayHi = this.initialFeed.length === 0;
      //  && !this.noTokens
      this.feed = this.initialFeed;
      // if (!this.sayHi) {
      //   this.$nextTick(this.$refs.inputText.$el.children[1].children[0].focus())
      // }
    }
  },
  mounted () {
    // console.log('ui r', this.$route);
    if (this.$route.query.cam && (+this.$route.query.cam === 1 || +this.$route.query.cam === 2)) {
      this.btnClicked = +this.$route.query.cam;
      if (this.btnClicked === 2) {
        this.modalInfo = this.$t('chat.callclick');
      }
    }
    this.feed = this.initialFeed;
    this.authorId = this.initialAuthorId;
  },
  beforeDestroy () {
    // const today_feed = this.feed.filter(msg => moment().format('ll') == moment(msg.created_at).format('ll') )
    // if (today_feed.length > 0)
    //   this.$emit('newOwnMessage', 'left chat!', true)
    // await this.onNewOwnMessage(`${this.profileData.name} left chat`, true)
    // console.log('feed: ', this.feed)
    // window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // onResize () {
    //   console.log('resize ', this.windowHeight, window.innerHeight)
    //   this.windowHeight = window.innerHeight
    // },
    // scrollToEnd () {
    //   console.log('scroll ', this.$refs.messagesContainer.scrollHeight)
    //   this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight
    // },
    sendHi (text) {
      this.onNewOwnMessage(text);
    },
    pushToFeed (element) {
      this.feed.push(element);
    },
    closePhoneVerify () {
      this.$store.commit('setPaid', false);
      this.needConfirmation = false;
    },
    async handleEmailInput () {
      this.showInputEmail = false;
      sessionStorage.setItem('emailUpdated', true);
      const cid = this.$storage.getCookie('_cid');
      const afid = this.$storage.getCookie('_afid');
      if (cid) {
        // console.log('postback', { clickId: cid, affId: afid, txId: data.phone, event: 'registration' })
        await this.$api.post('v1/postback', { clickId: cid, affId: afid, txId: this.profileData.phone, event: 'email_updated' });
      }
      // await fetch('https://ads.trafficjunky.net/ct?a=1000139011&member_id=13064&cb=[RANDOM_NUMBER]&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]&aclid=[ACLID]');
      this.$gtm.push({
        event: 'email_updated',
        phone: this.profileData.phone,
        country: this.profileData.country,
        date: new Date().toUTCString(),
        email: this.profileData.email,
        username: this.profileData.username
      });
    },
    handleCloseTopUp () {
      this.noTokens = false;
      if (!this.profileData.email_verified_at && this.$storage.getLocalStorage('_m1') === 'bbe14ec0-5260-42ea-b12e-7f7dc560d1f6' && !this.$store.state.emailSent) {
        if (!this.profileData.email.includes(this.$store.state.fakeDomain)) {
          this.showModal = true;
        }
      }
      // this.needConfirmation = true
      // if (this.profileData.phoneVerified) {
      //   this.needConfirmation = false
      // }
    },
    onInputMsg (value) {
      // console.log('input value: ', value)
      this.sayHi = value === '' && this.feed.length === 0;
    },
    onNewOwnMessage (message, image, imageUrl) {
      // const newOwnMessage = {
      //   id: this.authorId,
      //   contents: message,
      //   image,
      //   imageUrl,
      //   date: ''
      // };
      // const emailUpdated = sessionStorage.getItem('emailUpdated');
      // const notokens = sessionStorage.getItem('notokens');
      if (this.profileData.count_tokens === 0) {
        this.noTokens = true;
        // if (notokens === null) {
        //   sessionStorage.setItem('notokens', true);
        //   this.noTokens = true;
        // } else if (!emailUpdated && notokens && this.profileData.email.includes(this.$store.state.fakeDomain)) {
        //   this.showInputEmail = true;
        // }
        // this.sayHi = false;
        return;
      }
      // if (this.$store.state.justPaid) {
      //   if (!this.profileData.phoneVerified) {
      //     this.needConfirmation = true
      //     return
      //   }
      // }
      /** DOI **/
      if (!this.profileData.email_verified_at && this.$store.state.chat.messages.length === 3 && this.$storage.getLocalStorage('_m1') === 'bbe14ec0-5260-42ea-b12e-7f7dc560d1f6' && !this.$store.state.emailSent) {
        if (!this.profileData.email.includes(this.$store.state.fakeDomain)) {
          this.showModal = true;
        }
      }
      /** END DOI **/
      // if (!emailUpdated && this.profileData.email.includes(this.$store.state.fakeDomain)) {
      //   setTimeout(() => {
      //     this.showInputEmail = true;
      //   }, 3000);
      //   if (this.showedModalEmail >= 1) {
      //     return;
      //   }
      // }
      this.$emit('newOwnMessage', message);
    },
    handleClose () {
      // const openedWindows = this.$storage.getState('openedWindows') || []
      // if (openedWindows.length < 3) {
      //   openedWindows.push(window.open(window.location.origin, '_blank'))
      //   this.$storage.setState('openedWindows', openedWindows)
      // } else
      this.$router.replace('/');
    },
    handleCloseModalEmail () {
      this.showModal = false;
      this.showInputEmail = false;
      this.showedModalEmail++;
      if (this.showedModalEmail === 3 || this.profileData.count_tokens === 0) {
        this.showedModalEmail = 0;
        this.$router.push({ name: 'tokens' });
      }
    },
    async closeModal (e) {
      // console.log('b', this.btnClicked);
      const invitesent = Boolean(this.$storage.getLocalStorage('invitesent'));
      // console.log('c', invitesent);
      this.btnClicked = 0;
      if (!invitesent) {
        await this.$api.post('sanctum/telenes/send-message', { message: this.modalInfo, model_id: this.modelId, isSystem: true });
        const cid = this.$storage.getCookie('_cid');
        const afid = this.$storage.getCookie('_afid');
        if (cid) {
          this.$api.post('v1/postback', { clickId: cid, affId: afid, event: 'alert' });
        }
        this.$storage.setLocalStorage('invitesent', true);
      } else {
        this.$router.push({ name: 'tokens' });
      }
    }
  }
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
.window__header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-container {
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 5px;
  }
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  .status-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4CAF50;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
  }
}
.window__input__container {
  font-size: 1.2em;
}
.messages-list {
  font-size: 1.2em;
}
// .close-tokens {
//   position: absolute;
//   z-index: 2;
//   width: 3rem;
//   height: 1rem;
//   top: 0.5rem;
//   right: 3rem;
// }
// .closeX {
//   position: absolute;
//   z-index: 2;
//   font-size: 1.4rem;
//   width: 1.4rem;
//   height: 1.4rem;
//   top: 0.2rem;
//   right: 0.2rem;
// }
.back {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 10px;
  color: white;
}
// .more {
//   position: absolute;
//   z-index: 2;
//   top: 1.5rem;
//   right: 10px;
//   color: white;
// }
.blurred {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  pointer-events: none;
}
.mail_icon {
  position: absolute;
  right: 12px;
}
.alert {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 0.13rem solid #ffff;
  background: red;
  color: #ffff;
  font-size: 0.8rem;

  &-notification {
    top: -0.1rem;
    right: -0.2rem;
  }

  &-msg {
    top: -0.1rem;
    right: -0.2rem;
  }
}
.camclick {
  z-index: 999;
  line-height: 1.5rem;
}
</style>
