<template>
  <div class="modal__content">
    <!-- <div class="modal__content-img mb-s"><Chat :size="96" fillColor="#f19135"/></div> -->
    <div class="modal__content-text mb-m">
      {{ $t('chat.hello', {model: modelName}) }} <span style="font-size: 48px;position: relative;top: 10px;">👋</span>
    </div>
    <div class="gradient__wrap" v-for="index in 4">
      <Button
        class="hey"
        :title="$t('chat.text'+index)"
        :bg-color="button_color"
        @click.native="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import HumanGreeting from 'vue-material-design-icons/HumanGreetingVariant.vue';
import Chat from 'vue-material-design-icons/Forum.vue';
import Button from '../Button/Button';

export default {
  components: {
    Button,
    Chat,
    HumanGreeting
  },
  props:{
    img: {
      type: String,
      default: ''
    },
    modelName: {
      type: String,
      default: 'Susanne'
    },
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      button_color: '#fff'
    };
  },
  computed: {
  },
  methods: {
    closeComponent (value) {
      this.$emit('close', value);
    },
    handleSubmit (el) {
      // console.log(el.target.textContent.trim())
      this.$emit('sendHi', el.target.textContent.trim());
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__content{
  text-align: center;
  position: relative;
  max-width: 500px;
  border-radius: 0px;
  bottom: 10px;
  &-text{
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }
  &-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    margin-top: -80px;
    background: white;
    img{
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
    }
  }
}
.mb-s{
  margin-bottom: 16px;
}
.mb-m{
  margin-bottom: 24px;
}
.gradient__wrap{
  background: linear-gradient(90deg, #ff6565 -2.93%, #ffdc23 105.33%);
  border-radius: 20px;
  padding: 1px;
  max-width: 330px;
  margin: 5px auto;
  font-size: 14px;
  line-height: 12px;
  // font-family: "Roboto-Bold";
}
.hey {
  padding: 10px;
  // border-radius: 20px;
  // margin: 5px auto;
  font-size: 16px;
  line-height: 14px;
  // font-family: "Roboto-Bold";
  // padding: 5px 5px;
  // border: none;
  // outline: none;
  // font-family: "DMSans-Medium";
}
</style>
